<template>
    <v-container fluid fill-height class=" bg-login">
        <v-row justify="center"  class="ma-0">
            <v-col cols="12" md="8" lg="6" align="center">
                <v-card>
                    <v-card-text >
                        <img src="@/assets/C_logopackhai.png"  width="100%" >
                        <div class="fontheadregis py-4">สมัครสมาชิก</div>
                        <v-stepper
                        alt-labels
                        v-model="step"
                        >
                            <v-stepper-items >
                                <v-stepper-content step="3">
                                    <v-col cols="12" >
                                        <v-row justify="center" class="pb-4">
                                            <div class="title">
                                                สมัครสมาชิกเรียบร้อย ระบบจะทำการติดต่อกลับภายใน 2 ชั่วโมง
                                            </div>
                                        </v-row>
                                        <v-row justify="center">
                                            <v-btn  dark color="#a9001d" >กลับสู่หน้าหลัก</v-btn>
                                        </v-row>
                                    </v-col>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    data: () => ({
        Dataregister:[],
        step: 3,
    }),
    created() {
        this.loaddata()
    },
    methods: {
        async loaddata(){
            this.Dataregister = JSON.parse(localStorage.getItem('register_list'))
        }
    }
}
</script>