import axios from 'axios'
import Swal from 'sweetalert2'
import { header_token } from '@/website/token'
import { generalService_dotnet } from '@/website/global'

export function AlertSuccess (title) {
  Swal.fire({
    toast: true,
    timer: 1500,
    position: 'top',
    type: 'success',
    title: title==undefined && title==null ? 'สำเร็จ' : title,
    showConfirmButton: false,
    confirmButtonText: 'Yes!',
  })
}

export function AlertError (title) {
  Swal.fire({
    toast: true,
    // timer: 1500,
    position: 'top',
    type: 'error',
    title: title==undefined && title==null ? 'ไม่สามารถทำรายการได้' : title,
    showConfirmButton: true,
    confirmButtonText: 'Yes!',
  })
}

export function AlertWarning (title) {
  Swal.fire({
    toast: true,
    // timer: 1500,
    position: 'top',
    type: 'warning',
    title: title==undefined && title==null ? 'ไม่สามารถทำรายการได้' : title,
    showConfirmButton: true,
    confirmButtonText: 'Yes!',
  })
}

export async function SendError (platform,fromPath,fromApi,branchId,shopId,orderId,logInfo,logInfo2) {
  var applicationLog = {
    id: 0,
    platform: platform == undefined ? null : platform,
    fromPath: fromPath == undefined ? null : fromPath,
    fromApi: fromApi == undefined ? null : fromApi,
    branchId: branchId == undefined ? null : branchId,
    shopId: shopId == undefined ? null : shopId,
    orderId: orderId == undefined ? null : orderId,
    logInfo: logInfo == undefined ? null : logInfo,
    logInfo2: logInfo2 == undefined ? null : logInfo2
  }
  let response = await axios.post(generalService_dotnet+'Log/insert-log', {
    applicationLog: applicationLog
  }, {headers: header_token})
}
