<template>
  <v-app>
    <loading :loading="loading" />

    <v-container fluid fill-height class="bg-login">
      <v-row justify="center" class="ma-0">
        <v-col cols="12" md="8" lg="5" align="center">
          <v-card >
            <v-card-text>
              <img src="@/assets/C_logopackhai.png"  width="100%">
              <div class="fontheadregis py-4">ลงทะเบียนใช้งาน</div>

              <v-form ref="formrigister" class="pa-4" lazy-validation>
                <v-col cols="12" class="pb-0">

                  <v-alert v-if="isSuccess && result" dense text type="success">
                    <span class="fn-12">{{ message }}</span>
                  </v-alert>

                  <v-btn v-if="isSuccess && result" block depressed class="btn-login pa-0" @click="GoToHomePage()">
                    กลับสู่หน้าหลัก
                  </v-btn>

                  <v-alert v-if="!isSuccess && result" dense outlined type="error">
                    <span class="fn-12">{{ message }}</span>
                  </v-alert>

                  <v-row>
                    <v-col cols="4" v-if="isSuccess" class="text-left fn-12 pb-1">
                      ชื่อ-นามสกุล :
                    </v-col>
                    <v-col cols="8" v-if="isSuccess" class="text-left fn-12 pb-1">
                      {{ name }}
                    </v-col>
                    <v-col cols="12" v-if="!isSuccess" class="text-left fn-12 pb-1">
                      ชื่อ-นามสกุล :
                    </v-col>
                    <v-col cols="12" v-if="!isSuccess">
                      <v-text-field
                        autofocus
                        @keyup.enter="$refs.phone.focus()"
                        :value="name"
                        @change="value => name = Trim_value(value)"
                        autocomplete = "off"
                        ref="name"
                        outlined
                        required
                        dense hide-details
                        :rules="[ v => !!v || 'กรุณาระบุ', ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" v-if="isSuccess" class="text-left fn-12 pb-1">
                      เบอร์โทร :
                    </v-col>
                    <v-col cols="8" v-if="isSuccess" class="text-left fn-12 pb-1">
                      {{ phone }}
                    </v-col>
                    <v-col cols="12" v-if="!isSuccess" class="text-left fn-12 pb-1">
                      เบอร์โทร :
                    </v-col>
                    <v-col cols="12" v-if="!isSuccess">
                      <v-text-field
                        @keyup.enter="$refs.email.focus()"
                        :value="phone"
                        @change="value => phone = value"
                        @keypress="isNumberWNoDot($event)"
                        autocomplete = "off"
                        ref="phone"
                        outlined
                        required
                        dense hide-details
                        maxlength="10"
                        :rules="[ v => !!v || 'กรุณาระบุ', ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" v-if="isSuccess" class="text-left fn-12 pb-1">
                      อีเมล :
                    </v-col>
                    <v-col cols="8" v-if="isSuccess" class="text-left fn-12 pb-1">
                      {{ email }}
                    </v-col>
                    <v-col cols="12" v-if="!isSuccess" class="text-left fn-12 pb-1">
                      อีเมล :
                    </v-col>
                    <v-col cols="12" v-if="!isSuccess">
                      <v-text-field
                        @keyup.enter="$refs.producttype.focus()"
                        :value="email"
                        @change="value => email = Trim_value(value)"
                        autocomplete = "off"
                        ref="email"
                        outlined
                        required
                        dense hide-details
                        :rules="[ v => !!v || 'กรุณาระบุ', ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" v-if="isSuccess" class="text-left fn-12 pb-1">
                      ประเภทสินค้า :
                    </v-col>
                    <v-col cols="8" v-if="isSuccess" class="text-left fn-12 pb-1">
                      {{ producttype }}
                    </v-col>
                    <v-col cols="12" v-if="!isSuccess" class="text-left fn-12 pb-1">
                      ประเภทสินค้า :
                    </v-col>
                    <v-col cols="12" v-if="!isSuccess">
                      <v-text-field
                        :value="producttype"
                        @change="value => producttype = Trim_value(value)"
                        autocomplete = "off"
                        ref="producttype"
                        outlined
                        required
                        dense hide-details
                        :rules="[ v => !!v || 'กรุณาระบุ', ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="!isSuccess && isRegister">
                    <v-col cols="12">
                      <v-btn block depressed class="btn-login" @click="register()">
                        ลงทะเบียน 
                      </v-btn>
                    </v-col>
                  </v-row>

                </v-col>
              </v-form>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<style scoped>
 
 .btn-login{
  background-color:#a9001d !important;
  font-size: 1em !important;
  width:100% !important;
  height:50px !important;
  color: #ffffff !important;
}
</style>
<script>
  import axios from 'axios'
  import { header_token } from "@/website/token";
  import Loading from '@/website/components/Loading'
  import { shop_Service, homepage } from '@/website/global'
  import { isNumberWNoDot, Trim_value } from '@/website/global_function'
  import { AlertSuccess, AlertWarning, AlertError, SendError } from '@/website/global_alert'
  export default {
    components: {
      Loading,
    },
    data: () => ({
      loading: false,

      result: false,
      isSuccess: false,
      isRegister: true,
      message: null,
      registerId: 0,

      name: null,
      phone: null,
      email: null,
      producttype: null,
    }),
    async created(){

    },
    watch:{},
    methods: {
      isNumberWNoDot,
      Trim_value,
      GoToHomePage(){
        window.location.href = homepage;
      },
      async register(){
        try {
          if(this.$refs.formrigister.validate()){
            this.loading = true
            this.isRegister = false

            let response = await axios.post(shop_Service+'Register/register', {
              "name": this.name,
              "phone": this.phone,
              "email": this.email,
              "producttype": this.producttype,
            }, {headers: header_token})

            this.loading = false
            this.result = true
            if (response.status == 200){
              if (response.data.status && response.data.message == null){
                this.isSuccess = true
                this.message = "ลงทะเบียนเรียบร้อย กรุณารอเจ้หน้าที่ติดต่อกลับนะคะ"
                this.registerId = response.data.id
                this.Line_Notify()
              } else {
                // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
                this.SendError('shop','register',shop_Service+'Register/register',null,null,null,response.data.description,response.data.message)
              }
            } else {
              this.isRegister = true
              this.message = "ทำรายการไม่สำเร็จ"
              // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
              this.SendError('shop','register',shop_Service+'Register/register',null,null,null,null,null)
            }
          }
        } catch (e) {
          this.isRegister = true
          // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
          this.SendError('shop','register','register',null,null,null,e,null)
        }
      },
      async Line_Notify(){
        if (this.registerId != 0){
          try {
            let response = await axios.post(shop_Service+'Register/linenotify', {
              "Id": this.registerId,
            }, {headers: header_token})
            if (response.status == 200){
              if (response.data.message != null){
                // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
                this.SendError('shop','register',shop_Service+'Register/linenotify',null,null,null,response.data.description,response.data.message)
              }
            } else {
              // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
              this.SendError('shop','register',shop_Service+'Register/linenotify',null,null,null,null,null)
            }
          } catch (e) {
            // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
            this.SendError('shop','register','Line_Notify',null,null,null,e,null)
          }
        }
      },
    },
  }
</script>
