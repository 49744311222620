import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:" bg-login",attrs:{"fluid":"","fill-height":""}},[_c(VRow,{staticClass:"ma-0",attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"8","lg":"6","align":"center"}},[_c(VCard,[_c(VCardText,[_c('img',{attrs:{"src":require("@/assets/C_logopackhai.png"),"width":"100%"}}),_c('div',{staticClass:"fontheadregis py-4"},[_vm._v("สมัครสมาชิก")]),_c(VStepper,{attrs:{"alt-labels":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c(VStepperItems,[_c(VStepperContent,{attrs:{"step":"3"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticClass:"pb-4",attrs:{"justify":"center"}},[_c('div',{staticClass:"title"},[_vm._v(" สมัครสมาชิกเรียบร้อย ระบบจะทำการติดต่อกลับภายใน 2 ชั่วโมง ")])]),_c(VRow,{attrs:{"justify":"center"}},[_c(VBtn,{attrs:{"dark":"","color":"#a9001d"}},[_vm._v("กลับสู่หน้าหลัก")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }