<template>
    <v-container fluid fill-height class=" bg-login-ss">
        <!-- pc -->
        <div class="hidden-xs-only ml-n16">
            <v-row class="form-login ma-0 mr-16">
                <v-col cols="12" md="8" lg="6" align="center">
                    <v-card color="#29a4dd" class="rounded-xl">
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" align="center">
                                    <img src="@/assets/SS BG-02.png"  width="100%" >
                                </v-col>
                            </v-row>
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-stepper alt-labels v-model="stepper" class="rounded-xl">
                                    <v-stepper-items >
                                        <v-stepper-content step="3">
                                            <v-col cols="12" >
                                                <v-row justify="center" class="pb-4">
                                                    <div class="fontheadregis py-4">สมัครสมาชิกเรียบร้อย</div>
                                                </v-row>
                                                <v-row justify="center">
                                                    <v-btn class="btn-login" @click="toLogin">กลับสู่หน้า SIGN IN</v-btn>
                                                </v-row>
                                            </v-col>
                                        </v-stepper-content>
                                    </v-stepper-items>
                                </v-stepper>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <!-- pc -->
        <!-- mobile -->
        <v-row class="hidden-sm-and-up" justify="center">
            <v-col cols="12" md="8" lg="6" align="center">
                <v-card color="#29a4dd" class="rounded-xl">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" align="center">
                                <img src="@/assets/SS BG-02.png"  width="100%" >
                            </v-col>
                        </v-row>
                        <v-stepper
                        alt-labels
                        v-model="stepper"
                        class="rounded-xl"
                        >
                            <v-stepper-items >
                                <v-stepper-content step="3">
                                    <v-col cols="12" >
                                        <v-row justify="center" class="pb-4">
                                            <div class="fontheadregis py-4">สมัครสมาชิกเรียบร้อย</div>
                                        </v-row>
                                        <v-row justify="center">
                                            <v-btn class="btn-login" @click="toLogin">กลับสู่หน้า SIGN IN</v-btn>
                                        </v-row>
                                    </v-col>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    data: () => ({
        Dataregister:[],
        step: 3,
        stepper: 3,
        valid: true,
    }),
    created() {
        this.loaddata()
    },
    methods: {
        async loaddata(){
            this.Dataregister = JSON.parse(localStorage.getItem('register_list'))
        },
        async toLogin(){
            this.$router.push('/ss/login')
        }
    }
}
</script>