<template>
    <v-container fluid fill-height class=" bg-login-ss">
        <!-- <loading :loading="loading" /> -->
        <!-- pc -->
        <div class="hidden-xs-only ml-n16">
            <v-row class="form-login ma-0 mr-16">
                <v-col cols="12" md="8" lg="6" align="center">
                    <v-card color="#29a4dd" class="rounded-xl">
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" align="center">
                                    <img src="@/assets/SS BG-02.png"  width="100%" >
                                </v-col>
                            </v-row>
                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                                v-if="this.firstNext==1"
                                v-on:submit.prevent
                            >
                                <v-stepper alt-labels v-model="stepper" class="rounded-xl">
                                    <v-stepper-items >
                                        <v-stepper-content step="3">
                                            <v-col cols="12" >
                                                <v-row justify="center" class="pb-4">
                                                    <div class="fontheadregis py-4">สมัครสมาชิก</div>
                                                </v-row>
                                                <v-row justify="center">
                                                    <v-btn color="#4267b2" width="100%" height="50px">
                                                        <fb-signin-button
                                                        :params="fbSignInParams"
                                                        scope="public_profile,email"
                                                        @success="onSignInSuccess"
                                                        @error="onSignInError">
                                                            Sign up with Facebook
                                                        </fb-signin-button>
                                                    </v-btn>
                                                </v-row>
                                            </v-col>
                                        </v-stepper-content>
                                    </v-stepper-items>
                                </v-stepper>
                            </v-form>

                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                                v-if="this.firstNext==2"
                                v-on:submit.prevent
                            >
                                <v-stepper alt-labels v-model="stepper" class="rounded-xl">
                                    <v-stepper-items >
                                        <v-stepper-content step="3">
                                            <v-col cols="12" class="pb-0" >
                                                <v-row>
                                                    <v-subheader class="pa-0">กรอกเบอร์โทรศัพท์</v-subheader>
                                                </v-row>
                                                <v-row>
                                                    <v-text-field
                                                    v-model="telephone"
                                                    maxlength = "10"
                                                    autocomplete = "off"
                                                    autofocus
                                                    @keyup.enter="sendotp()"
                                                    @keypress="isNumberWNoDot($event)"
                                                    outlined
                                                    required
                                                    ref="reftelephone"
                                                    :rules="telRules"
                                                ></v-text-field>
                                                </v-row>
                                                <v-row>
                                                    <v-btn block
                                                    :disabled="!valid"
                                                    :loading ="loadingbtn"
                                                    v-if="next"
                                                    v-on:click="sendotp()"
                                                    depressed
                                                    class="btn-login" >ส่งรหัส OTP</v-btn>
                                                </v-row>
                                            </v-col>
                                        </v-stepper-content>
                                    </v-stepper-items>
                                </v-stepper>
                            </v-form>

                            <v-stepper alt-labels v-model="step" v-if="this.next==false" class="rounded-xl">
                                <v-stepper-header >
                                    <v-stepper-step class="px-0 strephead" :complete="step > 1" step="1" >ยืนยันตัวตน</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step class="px-0 strephead" :complete="step > 2" step="2">กรอกรายละเอียด</v-stepper-step>
                                </v-stepper-header>
                                <v-stepper-items>
                                    <v-form
                                        ref="formotp"
                                        v-model="valid"
                                        lazy-validation
                                        v-on:submit.prevent>
                                        <v-stepper-content step="1" class="strepcon">
                                            <v-col cols="12" class="pb-0" >
                                                <v-row justify="center" align="center">
                                                    <v-col cols="1" align="left" class="pa-0">
                                                        <v-btn icon  v-if="!next" @click="back()"> <v-icon>mdi-chevron-left</v-icon></v-btn>
                                                    </v-col>
                                                    <v-col cols="10" class="pa-0">
                                                        <div class="fonttitleform">กรุณาใส่รหัสยืนยันตัวตน</div>
                                                    </v-col>
                                                    <v-col cols="1"></v-col>
                                                </v-row>
                                                <v-row justify="center">
                                                    <div>รหัสยืนยันตัวตนของคุณถูกส่งไปที่</div>
                                                </v-row>
                                                <v-row justify="center">
                                                    <div>{{telephone}}</div>
                                                </v-row>
                                                <v-row>
                                                    <v-subheader class="pa-0">กรอกรหัส OTP</v-subheader>
                                                </v-row>
                                                <v-row>
                                                    <v-text-field
                                                        v-model="otpconfirm"
                                                        maxlength = "6"
                                                        autocomplete = "off"
                                                        @keyup.enter="confirm()"
                                                        @keypress="isNumberWNoDot($event)"
                                                        outlined
                                                        required
                                                        :rules="otpconfirmRules"
                                                    ></v-text-field>
                                                </v-row>
                                                <v-row class="pb-2">
                                                    <v-btn text block v-if="iterations != null" disable>
                                                        กรุณารออีก {{ iterations }} วิ ก่อนกดส่งอีกครั้ง
                                                    </v-btn>
                                                    <v-btn text block v-else @click="againotp()" color="#a9001d">ขอรหัส OTP อีกครั้ง</v-btn>
                                                </v-row>
                                                <v-row>
                                                    <v-btn
                                                    depressed
                                                    class="btn-login"
                                                    block
                                                    :disabled="!valid"
                                                    @click="confirm()">ยืนยัน</v-btn>
                                                </v-row>
                                            </v-col>
                                        </v-stepper-content>
                                    </v-form>
                                    <v-form
                                        ref="formregis"
                                        v-model="valid"
                                        lazy-validation
                                        v-on:submit.prevent>
                                        <v-stepper-content step="2" >
                                            <v-col cols="12">
                                                <v-row>
                                                    <v-subheader class="pa-0">ชื่อ-นามสกุล</v-subheader>
                                                </v-row>
                                                <v-row>
                                                    <v-text-field v-model="nameFace"
                                                        autocomplete = "off"
                                                        @keyup.enter="$refs.email.focus()"
                                                        outlined
                                                        required
                                                        :rules="[ v => !!v || 'กรุณาระบุชื่อ', ]"
                                                    />
                                                </v-row>
                                                <v-row>
                                                    <v-subheader class="pa-0">อีเมลล์</v-subheader>
                                                </v-row>
                                                <v-row>
                                                    <v-text-field
                                                        ref="email"
                                                        autocomplete = "off"
                                                        v-model="emailFace"
                                                        @keyup.enter="$refs.type.focus()"
                                                        outlined
                                                        required
                                                        :rules="emailRules">  </v-text-field>
                                                </v-row>
                                                <v-row>
                                                    <v-subheader class="pa-0">ที่อยู่ร้านค้า</v-subheader>
                                                </v-row>
                                                <v-row>
                                                    <v-text-field v-model="address"
                                                        autocomplete = "off"
                                                        @keyup.enter="$refs.select_shop_address_mapping.focus()"
                                                        outlined
                                                        required
                                                        :rules="[ v => !!v || 'กรุณาระบุที่อยู่', ]"/>
                                                </v-row>
                                                <v-row>
                                                    <v-subheader class="pa-0">รหัสไปรษณีย์</v-subheader>
                                                </v-row>
                                                <v-row>
                                                    <!-- <v-text-field v-model="postalcode"
                                                        autocomplete = "off"
                                                        maxlength = "5"
                                                        @keyup.enter="regis()"
                                                        outlined
                                                        required
                                                        :rules="postalcodeRules"/> -->
                                                    <v-autocomplete hide-details="auto"
                                                    v-model="select_shop_address_mapping"
                                                    :items="postcode_mapping"
                                                    :item-text="item => item.Postcode + ' ' + item.Province + ' ' + item.District + ' ' + item.SubDistrict"
                                                    item-value="Id"
                                                    placeholder="รหัสไปรษณีย์/จังหวัด/อำเภอ/ตำบล"
                                                    outlined
                                                    :rules="select_shop_address_mapping_rules"
                                                    required
                                                    return-object
                                                    style="margin-bottom: 30px"
                                                    />
                                                </v-row>
                                                <v-row>
                                                    <v-btn
                                                        block depressed
                                                        class="btn-login"
                                                        :disabled="!valid"
                                                        @click="regis"
                                                    >สมัครสมาชิก</v-btn>
                                                </v-row>
                                            </v-col>
                                        </v-stepper-content>
                                    </v-form>
                                </v-stepper-items>
                            </v-stepper>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <!-- pc -->

        <!-- mobile -->
        <v-row justify="center" class="ma-0 hidden-sm-and-up">
            <v-col cols="12" md="8" lg="6" align="center">
                <v-card color="#29a4dd" class="rounded-xl">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" align="center">
                                <img src="@/assets/SS BG-02.png"  width="100%" >
                            </v-col>
                        </v-row>
                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                            v-if="this.firstNext==1"
                            v-on:submit.prevent
                        >
                            <v-stepper
                            alt-labels
                            v-model="stepper"
                            class="rounded-xl"
                            >
                                <v-stepper-items >
                                    <v-stepper-content step="3">
                                        <v-col cols="12" >
                                                <v-row justify="center" class="pb-4">
                                                    <div class="fontheadregis py-4">สมัครสมาชิก</div>
                                                </v-row>
                                                <v-row justify="center">
                                                    <v-btn color="#4267b2" width="100%" height="50px">
                                                        <fb-signin-button
                                                        :params="fbSignInParams"
                                                        scope="public_profile,email"
                                                        @success="onSignInSuccess"
                                                        @error="onSignInError">
                                                            Sign up with Facebook
                                                        </fb-signin-button>
                                                    </v-btn>
                                                </v-row>
                                            </v-col>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </v-form>

                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                            v-if="this.firstNext==2"
                            v-on:submit.prevent
                        >
                            <v-stepper
                            alt-labels
                            v-model="stepper"
                            class="rounded-xl"
                            >
                                <v-stepper-items >
                                    <v-stepper-content step="3">
                                        <v-col cols="12" class="pb-0" >
                                            <v-row>
                                                <v-subheader class="pa-0">กรอกเบอร์โทรศัพท์</v-subheader>
                                            </v-row>
                                            <v-row>
                                                <v-text-field
                                                v-model="telephone"
                                                maxlength = "10"
                                                autocomplete = "off"
                                                autofocus
                                                @keyup.enter="sendotp()"
                                                @keypress="isNumberWNoDot($event)"
                                                outlined
                                                required
                                                ref="reftelephone"
                                                :rules="telRules"
                                            ></v-text-field>
                                            </v-row>
                                            <v-row>
                                                <v-btn block
                                                :disabled="!valid"
                                                :loading ="loadingbtn"
                                                v-if="next"
                                                v-on:click="sendotp()"
                                                depressed
                                                class="btn-login" >ส่งรหัส OTP</v-btn>
                                            </v-row>
                                        </v-col>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </v-form>

                        <v-stepper
                        alt-labels
                        v-model="step"
                        v-if="this.next==false"
                        class="rounded-xl"
                        >
                            <v-stepper-header >
                                <v-stepper-step class="px-0 strephead" :complete="step > 1" step="1" >ยืนยันตัวตน</v-stepper-step>
                                <v-divider></v-divider>
                                <v-stepper-step class="px-0 strephead" :complete="step > 2" step="2">กรอกรายละเอียด</v-stepper-step>
                            </v-stepper-header>
                            <v-stepper-items>
                                <v-form
                                    ref="formotp"
                                    v-model="valid"
                                    lazy-validation
                                    v-on:submit.prevent>
                                    <v-stepper-content step="1" class="strepcon">
                                        <v-col cols="12" class="pb-0" >
                                            <v-row justify="center" align="center">
                                                <v-col cols="1" align="left" class="pa-0">
                                                    <v-btn icon  v-if="!next" @click="back()"> <v-icon>mdi-chevron-left</v-icon></v-btn>
                                                </v-col>
                                                <v-col cols="10" class="pa-0">
                                                    <div class="fonttitleform">กรุณาใส่รหัสยืนยันตัวตน</div>
                                                </v-col>
                                                <v-col cols="1"></v-col>
                                            </v-row>
                                            <v-row justify="center">
                                                <div>รหัสยืนยันตัวตนของคุณถูกส่งไปที่</div>
                                            </v-row>
                                            <v-row justify="center">
                                                <div>{{telephone}}</div>
                                            </v-row>
                                            <v-row>
                                                <v-subheader class="pa-0">กรอกรหัส OTP</v-subheader>
                                            </v-row>
                                            <v-row>
                                                <v-text-field
                                                    v-model="otpconfirm"
                                                    maxlength = "6"
                                                    autocomplete = "off"
                                                    @keyup.enter="confirm()"
                                                    @keypress="isNumberWNoDot($event)"
                                                    outlined
                                                    required
                                                    :rules="otpconfirmRules"
                                                ></v-text-field>
                                            </v-row>
                                            <v-row class="pb-2">
                                                <v-btn text block v-if="iterations != null" disable>
                                                    กรุณารออีก {{ iterations }} วิ ก่อนกดส่งอีกครั้ง
                                                </v-btn>
                                                <v-btn text block v-else @click="againotp()" color="#a9001d">ขอรหัส OTP อีกครั้ง</v-btn>
                                            </v-row>
                                            <v-row>
                                                <v-btn
                                                depressed
                                                class="btn-login"
                                                block
                                                :disabled="!valid"
                                                @click="confirm()">ยืนยัน</v-btn>
                                            </v-row>
                                        </v-col>
                                    </v-stepper-content>
                                </v-form>
                                <v-form
                                    ref="formregis"
                                    v-model="valid"
                                    lazy-validation
                                    v-on:submit.prevent>
                                    <v-stepper-content step="2" >
                                        <v-col cols="12">
                                            <v-row>
                                                <v-subheader class="pa-0">ชื่อ-นามสกุล</v-subheader>
                                            </v-row>
                                            <v-row>
                                                <v-text-field v-model="nameFace"
                                                    autocomplete = "off"
                                                    @keyup.enter="$refs.email.focus()"
                                                    outlined
                                                    required
                                                    :rules="[ v => !!v || 'กรุณาระบุชื่อ', ]"
                                                />
                                            </v-row>
                                            <v-row>
                                                <v-subheader class="pa-0">อีเมลล์</v-subheader>
                                            </v-row>
                                            <v-row>
                                                <v-text-field
                                                    ref="email"
                                                    autocomplete = "off"
                                                    v-model="emailFace"
                                                    @keyup.enter="$refs.type.focus()"
                                                    outlined
                                                    required
                                                    :rules="emailRules">  </v-text-field>
                                            </v-row>
                                            <v-row>
                                                <v-subheader class="pa-0">ที่อยู่ร้านค้า</v-subheader>
                                            </v-row>
                                            <v-row>
                                                <v-text-field v-model="address"
                                                    autocomplete = "off"
                                                    @keyup.enter="$refs.select_shop_address_mapping.focus()"
                                                    outlined
                                                    required
                                                    :rules="[ v => !!v || 'กรุณาระบุที่อยู่', ]"/>
                                            </v-row>
                                            <v-row>
                                                <v-subheader class="pa-0">รหัสไปรษณีย์</v-subheader>
                                            </v-row>
                                            <v-row>
                                                <!-- <v-text-field v-model="postalcode"
                                                    autocomplete = "off"
                                                    maxlength = "5"
                                                    @keyup.enter="regis()"
                                                    outlined
                                                    required
                                                    :rules="postalcodeRules"/> -->
                                                <v-autocomplete hide-details="auto"
                                                v-model="select_shop_address_mapping"
                                                :items="postcode_mapping"
                                                :item-text="item => item.Postcode + ' ' + item.Province + ' ' + item.District + ' ' + item.SubDistrict"
                                                item-value="Id"
                                                placeholder="รหัสไปรษณีย์/จังหวัด/อำเภอ/ตำบล"
                                                outlined
                                                :rules="select_shop_address_mapping_rules"
                                                required
                                                return-object
                                                style="margin-bottom: 30px"
                                                />
                                            </v-row>
                                            <v-row>
                                                <v-btn
                                                    block depressed
                                                    class="btn-login"
                                                    :disabled="!valid"
                                                    @click="regis"
                                                >สมัครสมาชิก</v-btn>
                                            </v-row>
                                        </v-col>
                                    </v-stepper-content>
                                </v-form>
                            </v-stepper-items>
                        </v-stepper>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'
import Loading from '@/website/components/Loading'
import { isNumberWNoDot } from '@/website/global_function'
import { generalService_dotnet, agentService_dotnet, homepage } from "@/website/global";
import { header_token } from "@/website/backend/token";
  export default {
    components: {
    //   Loading,
    },
    data () {
      return {
        stepper: 3,
        fbSignInParams: {
            scope: 'email,public_profile',
            return_scopes: true
        },
        // page_loading: true,
        idImage:'',
        loginImage:'',
        mailImage:'',
        faceImage:'',
        isConnected: false,
        picFace:'',
        nameFace: '',
        emailFace: '',
        personalID: '',
        FB: undefined,

        loading: false,
        firstNext: 1,
        step: 1,
        next:true,
        iterations: null,
        interval: null,
        valid: true,
        loadingbtn: false,
        // dialogphone:false,
        refcode:'',
        telephone: '',
        name: '',
        email: '',
        typeproduct: '',
        address: '',
        postalcode: '',
        otpconfirm:'',
        postcode_mapping: [],
        select_shop_address_mapping: '',
        select_shop_address_mapping_rules: [],
        homepage:"https://dev-www.packhai.com/",
        otpconfirmRules:[
            v => !!v || 'กรุณาระบุ OTP',
            v => (/\d{6}/.test(v) && v.length == 6) || 'รหัส OTP ไม่ถูกต้อง'
        ],
        telRules: [
            v => !!v || 'กรุณาระบุเบอร์โทร',
            v => v.length >= 10 || 'กรุณาระบุเบอร์โทร',
        ],
        emailRules: [
            v => !!v || 'กรุณาระบุอีเมลล์',
            v => /.+@.+\..+/.test(v) || 'อีเมลล์ไม่ถูกต้อง'
        ],
        postalcodeRules:[
            v => !!v || 'กรุณาระบุรหัสไปรษณีย์ที่ตั้งร้านค้า',
            v => (/\d{5}/.test(v) && v.length == 5) || 'รหัสไปรษณีย์ไม่ถูกต้อง'
        ],
      }
    },
    async created(){
        this.homepage = homepage
        this.refcode = this.$route.query.ref
        await axios.post(generalService_dotnet+'General/get-postcodemapping', {},
            { headers: header_token})
            .then(response =>
                {
                localStorage.setItem('postcodeMapping_dotnet', JSON.stringify(response.data))
                this.postcode_mapping = response.data
                })
        window.fbAsyncInit = function() {
            this.FB.init({
              appId      : '2978410389108595',
              cookie     : true,  // enable cookies to allow the server to access the session
              xfbml      : true,  // parse social plugins on this page
              version    : 'v11.0' // use graph api version 2.8
            });
         };
         (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
    },
    watch:{

    },
    methods: {
        isNumberWNoDot,
        async onSignInSuccess (response) {
            // console.log(response.authResponse.userID)
            window.FB.api('/me', 'GET', { fields: 'id,name,email,picture' },
                async userInformation => {
                    if(userInformation.id){
                        // localStorage.setItem('Fb_Profile', JSON.stringify([
                        //     userInformation.id,
                        //     userInformation.name,
                        //     userInformation.email
                        // ]));
                        this.personalID = userInformation.id;
                        this.nameFace = userInformation.name;
                        this.emailFace = userInformation.email;
                        this.picFace = userInformation.picture["data"]["url"];
                        if( userInformation.id ){
                            let response = await axios.post(
                                // "https://localhost:5001/AgentRegister/get-facebook-auth",
                                agentService_dotnet + 'AgentRegister/get-facebook-auth',
                                {
                                    'refcode': this.refcode,
                                    'faceId': userInformation.id,
                                    'faceName': userInformation.name,
                                    'faceEmail': userInformation.email,
                                    'faceImg': userInformation.picture["data"]["url"],
                                },
                                { headers: header_token }
                            )
                            if(response.data.status == true) {
                                this.firstNext = 2;
                            } else if(response.data.nameRegis == true) {
                                await this.$swal({
                                    position: 'top',
                                    type: 'info',
                                    text: 'Facebook นี้เป็นสมัครชิกแล้ว ไปหน้า Login',
                                })
                                await this.$router.push('/ss/login')
                                await window.location.reload();
                            } else if (response.data.nameRegis == false) {
                                await this.$swal({
                                    position: 'top',
                                    type: 'info',
                                    text: 'link สมัครไม่ถูกต้อง กรุณาติดต่อร้านค้าต้นทาง',
                                })
                            }
                        }
                    } else {
                        console.log("User cancelled login or did not fully authorize.")
                    }
                }
            )
        },
        onSignInError (error) {
            console.log('OH NOES', error)
        },

        async sendotp() {
            if(this.$refs.form.validate()){
                this.loading = true
                let response = await axios.post(
                    // "https://localhost:5001/AgentRegister/get-otp-number",
                    agentService_dotnet + 'AgentRegister/get-otp-number',
                    {
                        'phone': this.telephone,
                        'faceId': this.personalID,
                    },
                    { headers: header_token }
                );
                if (response.data.status) {
                    if (response.data.nameRegis == true) {
                        await this.$swal({
                            position: 'center',
                            type: 'error',
                            text: response.data.message,
                        })
                    }
                    else {
                        this.firstNext = 3;
                        this.next = false;
                        this.start()
                    }
                }
            }
            this.loading = false;
        },

        back(){
            this.next = true;
        },
        start() {
              this.iterations = 60;
              this.interval = setInterval(this.foo, 1000);
        },
        foo() {
            this.iterations--;
            if (this.iterations == 0){
              clearInterval(this.interval);
              this.iterations = null
            }
        },
        async againotp(){
            let response = await axios.post(
                    // "https://localhost:5001/AgentRegister/get-otp-number",
                    agentService_dotnet + 'AgentRegister/get-otp-number',
                    {
                        'phone': this.telephone,
                        'faceId': this.personalID,
                    },
                    { headers: header_token }
            );
            if (response.data.status){
                this.start()
            }
        },

        async confirm(){
            if(this.$refs.formotp.validate()){
                this.loading = true
                let response = await axios.post(
                    // "https://localhost:5001/AgentRegister/get-verify-otp",
                    agentService_dotnet + 'AgentRegister/get-verify-otp',
                    {
                        "phone": this.telephone ,
                        "otp": this.otpconfirm
                    },
                    { headers: header_token }
                );
                if (response.data.status) {
                    if (response.data.alertMessage != null) {
                        await this.$swal({
                            position: 'center',
                            type: 'error',
                            text: response.data.alertMessage,
                        })
                    }
                    else {
                    this.step = 2
                    }
                }
                this.loading = false
            }
        },

        async regis(){
            if(this.$refs.formregis.validate()){
                let response = await axios.post(
                    // "https://localhost:5001/AgentRegister/get-add-info",
                    agentService_dotnet + 'AgentRegister/get-add-info',
                    {
                        'refcode': this.refcode,
                        'phone': this.telephone ,
                        'name': this.nameFace,
                        'email': this.emailFace,
                        'address': this.address,
                        'postcode': this.select_shop_address_mapping['Id'],
                        'faceId': this.personalID,

                    },
                    { headers: header_token }
                )
                if (response.data.status){
                    window.open("/thankyou-agent");
                    this.cleardata()
                    this.next = true;
                    // this.step = 3
                }
                else{
                    await this.$swal({
                            position: 'center',
                            type: 'warning',
                            title: 'เกิดข้อผิดพลาด',
                            text: 'กรุณาลองใหม่',
                        })
                }
            }
            console.log(this.select_shop_address_mapping)

        },

        cleardata(){
            this.telephone= '',
            this.name= '',
            this.email= '',
            this.typeproduct= '',
            this.postalcode= '',
            this.otpconfirm=''
        },

        async testButton1() {
            this.firstNext = 2;
        },
        async testButton2() {
            this.firstNext = 3;
            this.next = false;
        },
        async testButton3() {
            this.step = 2
        },
        async testButton4() {
            window.open("/ss/thankyou");
            this.cleardata()
            this.next = true;
        },
    },
  }
</script>

<style>
    .button-facebook{
        color:white;
        min-width: 150px;
        background-color: #000000a1;
        height: 2.5rem;
        border-radius: 2rem;
        font-weight: 400;
        font-size: 0.8rem;
    }

    .fb-signin-button {
        /* This is where you control how the button looks. Be creative! */
        display: inline-block;
        padding: 17px;
        width: 100%;
        border-radius: 3px;
        background-color: #4267b2;
        color: #fff;
    }
</style>
